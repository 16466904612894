import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  SeresSilkDuvetProductSection,
  MeetOurHappySleepersSection,
  WoosaPromiseIconGridSection,
  CompleteYourNightsSection,
  SeresSilkDuvetAccordionSection,
  SeresSilkDuvetBenefitSection,
  SeresSilkDuvetIntroSection,
  SeresSilkDuvetSubIntroSection,
  SeresSilkDuvetReviewGridSection,
  SeresSilkProductFixedBottomBar,
} from '../../components/sections';

const SeresSilkDuvetPage: React.FC = () => (
  <Layout>
    <SEO
      title="Buy Mulberry Silk Duvet Online in Singapore ✔️"
      description="A luxuriously soft, silk-filled duvet insert. Only the highest quality silk, graded 6A, is used. This duvet hugs your body naturally and keeps you cool in the heat and warm on chilly nights."
      meta={[
        {
          name: 'keywords',
          content: 'mulberry silk duvet, silk duvet singapore, duvet singapore',
        },
      ]}
    />
    <SeresSilkDuvetProductSection />
    <SeresSilkDuvetAccordionSection />
    <SeresSilkDuvetIntroSection />
    <SeresSilkDuvetSubIntroSection />
    <SeresSilkDuvetBenefitSection />
    <MeetOurHappySleepersSection />
    <WoosaPromiseIconGridSection />
    <CompleteYourNightsSection type="SeresSilkDuvet" />
    <SeresSilkDuvetReviewGridSection />
    <SeresSilkProductFixedBottomBar />
  </Layout>
);

export default SeresSilkDuvetPage;
